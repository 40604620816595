"use client";
import React, { useState, useEffect } from "react";
import { Sidebar } from "..";
import Topbar from "../Topbar";
import Footer from "../Footer";
import Header from "../Header";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getNavMenuData } from "../../app/apis/getApis";
import ReservationOffcanvas from "../ReservationOffcanvas";
import { usePathname } from "next/navigation";
import WishlistOffcanvas from "../WishlistOffcanvas";
import VerifyEmail from "../VerifyEmail";
import { useUserStore } from "../../store/User";
import { googleMapsKey } from "../../utility/env";
import { Alert } from "react-bootstrap";



interface coordiate {
  lat: number
  lng: number
}

const Layout = ({ children }: any) => {
  const [isMounted, setIsMounted] = useState(false);
  const { user, setLocation } = useUserStore((state: any) => state)
  const [isLocationDenied, setIsLocationDenied] = useState<boolean>(false)
  const pathname = usePathname();

  const [showHomeNavbar, setShowHomeNavbar] = useState(true);

  useEffect(() => {
    let handleScroll: any;
    if (typeof document !== "undefined") {
      handleScroll = () => {
        const heroBanner = document.getElementById("heroBanner");
        if (heroBanner) {
          const offset = heroBanner.offsetHeight;
          setShowHomeNavbar(window.scrollY <= offset);
        }
      };

      document.addEventListener("scroll", handleScroll);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (typeof document !== "undefined") {
        document.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const [isSandwichOpen, setisSandwichOpen] = useState(false);

  const navDataQuery = useQuery({
    queryKey: ["navMenuData"],
    queryFn: getNavMenuData,
  });

  const sandwichTriger = () => {
    setisSandwichOpen((prev) => !prev);
  };

  useEffect(() => {
    setIsMounted(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);
  if (!isMounted) {
    return null;
  }

  function getCityFromCoords(loca: coordiate) {
    const lat = loca?.lat;
    const lng = loca?.lng;

    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${googleMapsKey}`;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.status === "OK") {
          const results = data.results;
          const city = results.find((result: any) => result.types.includes("locality"))?.address_components[0].long_name;
          setLocation(city)
        } else {
          console.log("Geocoding failed: " + data.status);
        }
      })
      .catch(error => console.error('Error with Geocoding API:', error));
  }




  function showPosition(position: any) {
    getCityFromCoords({
      lat: position.coords.latitude,
      lng: position.coords.longitude
    })
  }

  function showError(error: any) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.log("User denied the request for Geolocation.");
        setIsLocationDenied(true)
        break;
      case error.POSITION_UNAVAILABLE:
        console.log("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        console.log("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        console.log("An unknown error occurred.");
        break;
    }
  }

  return (
    <>
      {pathname.includes("login") || pathname.includes("signup") ? (
        <></>
      ) : (
        <>

          <Sidebar setisSandwichOpen={setisSandwichOpen} isSandwichOpen={isSandwichOpen} navData={navDataQuery?.data?.data.body.data} />
          {user ? ((Boolean(user?.is_email_verified)) || (Boolean(user?.is_phone_number_verified))) ? "" : <VerifyEmail /> : ""}
          <Alert className="w-100 p-2 text-center " variant="warning" show={isLocationDenied}  >Location access is off. Enable it in your browser for better access to products and seamless order processing.</Alert>
          <Topbar />
          <Header sandwichTriger={sandwichTriger} navData={navDataQuery?.data?.data.body.data} showClassicNav={showHomeNavbar} />
        </>
      )}
      <>{children}</>
      {pathname.includes("login") || pathname.includes("signup") ? (
        <></>
      ) : (
        <>
          <Footer />
        </>
      )}
    </>
  );
};

export default Layout;
