import React from 'react'
import { Spinner } from 'react-bootstrap'
import styles from "../styles/LoadingDots.module.css"
function LoadingDots() {
  return (<>
    <Spinner animation="grow" className={`${styles.loading_0}`}  size="sm" variant="primary" />
     <Spinner animation="grow" className={`${styles.loading_1}`}  size="sm" variant="secondary" />
      <Spinner animation="grow" className={`${styles.loading_2}`}  size="sm" variant="success" />
      </>)
}

export default LoadingDots