import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { useMutation } from "@tanstack/react-query";
import { reSendEmail } from "../app/apis/postApis";
import { useUserStore } from "../store/User";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import LoadingDots from "./LoadingDots";

function VerifyEmail() {
  const [text, setText] = useState(false);
  const { user, token, setUser, setToken, updatePhoneNumberOfUser } = useUserStore((state: any) => state);
  const mutation = useMutation({
    mutationFn: reSendEmail,
    onSuccess: (data) => {
      setText(true);
      setTimeout(() => {
        setText(false);
      }, 2000);
    },
  });
  const sendEmail = async () => {
    mutation.mutate({ email: user?.email, token: token });
  };
  return (
    <div>
      {" "}
      <Alert className="py-1 d-flex justify-content-between" key={1} variant={"warning"}>
        <p className="m-0">
          <FontAwesomeIcon className="me-2" icon={faExclamationTriangle} />
          Verification Pending: A verification link has been sent to your registered email. Follow the instructions in the email to get verified.
        </p>

        {mutation?.isLoading ? (
          <button className="p-1 border-0 bg-white px-2">
            <LoadingDots />
          </button>
        ) : (
          <>
            {!text ? (
              <button onClick={sendEmail} className="p-1 border-0 bg-white px-2">
                Resend Email
              </button>
            ) : (
              <button style={{ pointerEvents: "none" }} className="p-1 border-0 bg-white px-2 ">
                <FontAwesomeIcon icon={faEnvelope} /> Check your email
              </button>
            )}
          </>
        )}
      </Alert>
    </div>
  );
}

export default VerifyEmail;
